html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: black;
  background-color: white;
}

body.dark-mode {
  background-color: black;
  color: white;
}

#root input:focus {
  box-shadow: none;
  outline: none;
}

body.show-overlay {
  overflow: hidden;
}

body,
h1,
h2,
h3,
h4,
.icon span,
.icon em,
h5 {
  font-family: 'Montserrat', sans-serif;
}

.container {
  position: relative;
}

#root div.ui.container {
  width: 90% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  padding: 1em 0 2em 0;
}

#root main.inner-wrapper div.ui.container {
  padding: 1em 0 2em 0;
}

#masonry-grid,
.main-content,
#root main.inner-wrapper div.ui.container {
  min-height: 60vh;
  background-color: transparent;
}

button:disabled {
  opacity: 0.5;
}

.footer ul li,
ul,
ol,
figure {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}

.main-content aside ol,
.main-content aside ul,
.main-content .body ol,
.main-content .body ul {
  padding-inline-start: 2em;
}

figure {
  margin: 0;
  max-width: 100%;
}

ul.menu {
  margin: 0 0 0 2em;
  padding: 0;
  list-style: none;
}

ul.main-menu li {
  list-style: none;
  font-size: 1.5em;
}

ul.main-menu li,
.gallery .container > h1.main {
  text-transform: uppercase;
}

.gallery .container > h1.main {
  position: relative;
  top: 1em;
}

#root header .main-nav i.bars {
  font-size: 2rem;
  position: absolute;
  top: 0.5em;
  right: 2%;
  transition: transform 0.5s ease-in-out;
}

.inner-wrapper a {
  color: black;
}

.footer ul a:hover,
main.inner-wrapper a:hover {
  text-decoration: underline;
}

header nav.main-nav a {
  display: block;
}

.main-content,
article,
header nav.main-nav i.icon,
header nav.main-nav a {
  position: relative;
}

#root i.icon.close:before,
#root i.icon.arrow.right:before,
#root i.icon.arrow.left:before {
  font-family: 'Montserrat', sans-serif;
}

#root i.icon.arrow.left:before {
  content: '\2190';
}

#root i.icon.arrow.right:before {
  content: '\2192';
}

#root i.icon.close:before {
  content: '\00D7';
}

header nav.main-nav {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

header nav.main-nav ul.menu {
  position: absolute;
  max-height: 0;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  margin-left: 0;
  padding-top: 3em;
}

header.show-menu i.bars,
header.show-menu .user-details {
  z-index: 101;
}

header.show-menu i.bars {
  transform: rotate(90deg) translate(25%, -25%);
}

header nav.main-nav ul li.active a,
header nav.main-nav ul li:hover a {
  filter: invert(1);
}

header nav.main-nav ul li a.contribute i.icon {
  transform: scale(0.96, 1.12);
}

header nav.main-nav ul li i.icon {
  margin: 0 0 0 0.5em;
}

footer.footer .footer-menu {
  margin-top: 3.5em;
}

footer.footer .credit-links {
  margin-bottom: 2em;
}

footer.footer .footer-menu li {
  margin: 1em auto;
  text-align: center;
}

footer.footer .credit-links li {
  margin: 0.75em auto;
  text-align: center;
}

time,
i.icon,
header nav.main-nav ul li .logged-out,
.logged-in header nav.main-nav ul li .logged-in {
  display: inline-block;
  position: relative;
}

.logged-in header nav.main-nav ul li .logged-out,
header nav.main-nav ul li .logged-in {
  display: none;
}

header nav.main-nav a.contribute {
  display: inline-block;
  border: solid 2px black;
  padding: 0.125em 0.125em 0.125em 0.25em;
  margin: 0.125em;
  width: auto;
}

article.post > .text-content,
.main-area,
aside {
  position: relative;
}

.main-content > .text-content {
  margin-top: 1.75rem;
  margin-bottom: 2rem;
}

.main-content article.post {
  margin: 0 0 2.25em 0;
}

.main-area img {
  max-width: 100%;
}

.wrapper img {
  user-select: none;
}

.ui.segments {
  margin: 0;
}

.inner-wrapper > .main-content > .segments {
  margin-top: 1rem;
}

#root .segments article {
  clear: both;
}

#root .segments article .text-content {
  top: 0;
}

#root .ui.horizontal.segments {
  background-color: transparent;
}

#root .black-bg,
#root .black-bg .ui.segment {
  background-color: black;
}

#root .black-bg header nav.main-nav a.contribute {
  border-color: white;
}

#root .black-bg,
#root .black-bg .inner-wrapper a,
#root .black-bg .ui.segment {
  color: white;
}

#root .black-bg header .logo,
#root .black-bg footer.footer .logos {
  filter: invert(100%);
}

.right-col-offset .main-content .main-area {
  padding: 0.75em 0 1em 0;
}

.right-col-offset .main-content .two-thirds {
  width: 100%;
}

#masonry-grid {
  position: relative;
  margin: 0.5% -0.5%;
}

#column-images {
  flex-flow: column wrap;
}

#column-images > figure {
  margin: 0 auto 3vw auto;
}

.media figure,
.media figure img {
  max-width: 100%;
}

.right-col-offset .main-content .main-area .wp-block-image img,
.right-col-offset .main-content .main-area img.large {
  width: 100%;
  height: auto;
}

#root .gallery .top-bar .num-images,
#root .gallery .top-bar .num-artists,
#root .gallery .top-bar .artists {
  opacity: 0;
}

.right-col-offset .main-content .extra-area {
  margin-left: 3em;
  width: calc(100% - 3em);
}

.wp-block-embed__wrapper iframe {
  width: 100%;
  max-width: 100%;
}

.right-col-offset .extra-area .wp-block-embed__wrapper iframe {
  height: calc(50.625vw - 1.6875em);
}

.main-area > .wp-block-image,
.extra-area > .wp-block-embed__wrapper {
  margin-bottom: 1em;
}

.right-col-offset .main-content > aside {
  padding: 0.5em 0 1em 0;
  width: 100%;
}

.news .right-col-offset .main-content > aside {
  min-height: 80rem;
}

aside article {
  clear: both;
}

.right-col-offset .main-content > .main-area > h2,
.main-content > h1,
.right-col-offset .main-content > aside > h1 {
  font-size: 2rem;
  text-transform: uppercase;
  margin: 0;
  text-align: right;
  position: relative;
}
h1 > time,
h1 > em,
h1 > span {
  display: inline-block;
}
.main-content > time,
.main-content > aside > time {
  font-weight: bold;
}

.main-content h1 > span.title {
  top: 0.125em;
  position: relative;
}

.right-col-offset .main-content > .main-area > h2 {
  width: 100%;
}

.news .main-content > h1,
.right-col-offset .main-content > h1,
.right-col-offset .main-content > aside > h1 {
  position: absolute;
  transform: rotate(-90deg) translate(0, -0.5em);
  transform-origin: 0 0;
  left: -1rem;
  padding-top: 0.25em;
}

.hall-of-fame .container > h1 {
  position: absolute;
  top: 43rem;
  left: 0;
  font-size: 1.2em;
  white-space: nowrap;
  margin-right: 1em;
  width: 40rem;
  transform: rotate(-90deg);
  transform-origin: 0 0;
  z-index: 14;
  text-align: right;
  margin-top: 0;
  padding-top: 0;
}

.right-col-offset .main-content > aside.side-panel > h1 {
  left: 0;
}

.right-col-offset .main-content > h1 {
  padding-top: 1.625rem;
  padding-right: 2.25rem;
}

.news .main-content > h1 {
  width: 10em;
  top: 11em;
  left: 0.375em;
  text-align: right;
  text-transform: uppercase;
}

#root .news main.inner-wrapper div.ui.container {
  padding-top: 1em;
}

.news .news-items {
  margin-left: 3em;
}

.inner-content.top-content > .text-content {
  min-height: 200px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.news-items article .text-content h3 {
  margin: 0.5rem 0;
  float: left;
}

.news-items article .text-content time {
  display: block;
  float: right;
  margin: 0.5rem 0 0.5rem 2rem;
}

.news-items article .text-content .body {
  clear: both;
}

.right-col-offset .main-content > h1,
.right-col-offset .main-content > aside > h1 {
  width: 45em;
  top: 45.375em;
}

.main-content > .text-content,
.right-col-offset .main-content > time,
#root .right-col-offset .main-content > aside > .login-form,
.right-col-offset .main-content > aside > article,
.right-col-offset .main-content > aside > time {
  margin-left: 3rem;
}

.right-col-offset .main-content > article.text-content {
  margin-left: 4.5rem;
}

.right-col-offset .main-content > aside,
.right-col-offset .main-content > article.text-content {
  max-width: calc(100% - 3rem);
}

.right-col-offset .main-content > aside > time {
  margin-bottom: 1em;
}

#root .right-col-offset .main-content > aside > .login-form {
  margin-top: 0;
}

.gallery .ReactTags__selected {
  position: relative;
}

.gallery .ReactTags__selected .ReactTags__tag {
  position: absolute;
  bottom: 0.5em;
  left: 0.5em;
  z-index: 11;
}

.gallery .ReactTags__selected .ReactTags__tagInput {
  width: 100%;
  border: solid 1px black;
}

.gallery .ReactTags__selected .ReactTags__tagInput input {
  width: 100%;
  padding: 0 0.25em;
}

.gallery .is-filtered .ReactTags__selected .ReactTags__tagInput input {
  opacity: 0;
}

#root ul.main-menu li {
  line-height: 1.25em;
}

ul.main-menu li a {
  color: black;
  display: inline-block;
  padding: 0;
  background-color: white;
  padding: 0 0.25em;
}

#root .black-bg ul.main-menu li a {
  background-color: black;
}

.wrapper,
#root {
  margin: 0 auto;
  padding: 0;
  position: relative;
}

.wrapper {
  max-width: 100%;
}

#root div.ui.container,
.wrapper .inner-wrapper {
  max-width: 1600px;
}

.wrapper .inner-wrapper {
  position: relative;
  margin: 0 auto;
}

.wrapper > header {
  padding: 1.5em 5% 1em 5%;
}

i.mail:hover {
  color: #009900;
}

.custom.login-form .actions i.icon {
  transition: transform 0.5s ease-in-out;
}

#root .custom.login-form .actions i.icon:hover,
.mail-login-mode i.mail {
  transform: skew(-7.5deg);
}

i.google:hover {
  color: #cc0000;
}

i.facebook:hover {
  color: #000099;
}

header .user-details i.icon,
.login-buttons i.icon {
  margin-left: 0.5em;
}

header .user-details i.icon {
  font-size: 1.25rem;
}

.login-buttons i.icon {
  font-size: 2rem;
  transition: color 0.5s ease-in-out, transform 0.5s ease-in-out;
  cursor: pointer;
}

.gallery .ReactTags__selected .ReactTags__tagInput input,
#root .ui.segments {
  border: none;
  box-shadow: none;
  border-radius: 0;
}

.container .user-images figure > i.check {
  position: absolute;
  font-size: 1rem;
  top: 0.5em;
  right: 0.5em;
  opacity: 0;
  color: green;
}

#root .container .user-images figure.active > i.check {
  font-size: 1.8rem;
  right: 2em;
  top: 0.25em;
}

#root .container .user-images figure:hover > i.check {
  opacity: 0.5;
}

#root input.textfield,
#root input.password,
.edit-panel .ReactTags__tagInputField,
.edit-panel textarea {
  border: solid 1px black;
  padding: 0.125em;
  background: none;
}

#root a.no-images,
#root .edit-panel.disabled .react-tags-wrapper {
  pointer-events: none;
}

#root .edit-panel.disabled .react-tags-wrapper .ReactTags__remove,
#root .edit-panel.disabled .ReactTags__tagInputField {
  display: none;
}

#root .edit-panel.disabled input.textfield,
#root .edit-panel.disabled textarea {
  border-color: transparent;
}

.edit-panel h4 {
  margin: 0;
}

.container .horizontal {
  display: block;
}

#root .login-form,
#root .user-details {
  height: 2em;
  display: flex;
  flex-flow: row nowrap;
  margin: 1.5em 0;
  clear: both;
}

@media screen and (max-width: 660px) {
  #root aside .login-form {
    flex-flow: row wrap;
    height: 3.5em;
  }

  #root aside .login-form .label {
    margin-bottom: 0.75em;
  }
}

#root .user-details {
  margin: 0.5em 0;
}

#root .user-details img {
  height: 100%;
  width: auto;
}

#root .login-form > .login-buttons {
  display: block;
  white-space: nowrap;
}

#root .login-form > .login-buttons,
#root .user-details > i.log,
#root .user-details > button {
  margin-left: 1em;
}

#root .user-details i,
#root .user-details button,
#root .user-details span {
  display: inline-block;
  white-space: nowrap;
}

#root .user-details i.user {
  margin-left: 0;
}

#root .user-details > .user-display {
  display: inline-block;
  font-size: 0.75em;
  margin: 0.5em;
  text-align: right;
}

header i.icon {
  cursor: pointer;
}

header .logo {
  float: left;
  width: 66.667%;
  height: 9em;
  background-position: top left;
  background-size: contain;
}

.backdrop .back,
header .logo {
  background-repeat: no-repeat;
}

header .logo {
  background-image: url(/images/portrait-of-an-egg-gt.svg);
}

header .main-nav .user-widget {
  position: absolute;
  top: 4.5em;
  right: 2.5%;
}

.logo figcaption {
  position: absolute;
  top: 0;
  left: -999em;
}

#root .wrapper main {
  clear: both;
}

#root .login-form p {
  margin-block-start: 0;
  margin-block-end: 0;
}

#root .login-form p.label {
  min-width: 6em;
}

#root .custom.login-form {
  flex-flow: column nowrap;
  height: auto;
}

#root .custom.login-form input.password,
#root .custom.login-form input.textfield {
  margin: 0.25em 0;
}

aside.text-content > p.message {
  margin-bottom: 1em;
  padding-bottom: 1em;
  margin-left: 3rem;
  border-bottom: dashed 1px black;
}

aside.text-content ul.error,
aside.text-content > p.error {
  color: red;
}

aside.text-content p.message {
  color: green;
}

#root .custom.login-form .actions i.icon {
  font-size: 1.375rem;
}

#root .custom.login-form .actions > .submit,
#root .custom.login-form .actions p.forgotten,
#root .custom.login-form .actions strong {
  position: relative;
  font-weight: normal;
  cursor: pointer;
  user-select: none;
}

#root .custom.login-form .actions > .submit,
#root .custom.login-form .actions > strong {
  display: block;
  width: 100%;
  max-width: 20em;
  border: solid 1px black;
}

#root .uploader .expand-text:hover,
#root .custom.login-form .actions > .submit:hover,
#root .custom.login-form .actions > strong:hover {
  background-color: black;
  color: white;
}

#root .custom.login-form .actions > strong {
  padding: 0.25em 0.5em;
  margin: 1.5em 0;
}

#root .custom.login-form .actions > .submit {
  padding: 0.25em 0.25em 0.25em 2.5em;
  margin: 0.5em 0;
}

#root .custom.login-form .actions > .submit i.icon {
  position: absolute;
  top: 0;
  left: 0.25em;
}

#root .custom.login-form .actions strong:hover {
  font-style: italic;
}

#root .custom.login-form input {
  width: 100%;
  max-width: 20em;
}

#root .horizontal figure.segment {
  position: relative;
  display: block;
  height: auto;
  border: none;
  box-shadow: none;
  font-size: 0;
  overflow: hidden;
  z-index: 2;
}

#masonry-grid .column > figure.segment {
  max-width: 100%;
  width: auto;
  flex: 1 1 auto;
  padding: 4%;
  margin: 0.0625rem auto;
  transition: transform 0.5s ease-in-out;
}

#root .horizontal > figure.segment.active,
.message-pane {
  position: absolute;
  text-align: center;
}

#root .horizontal > figure.segment.active {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  z-index: 51;
  padding: 0;
}

.message-pane {
  top: 3em;
  padding: 1em;
  background-color: rgba(255, 255, 255, 0.9);
  left: 2.5%;
  right: 2.5%;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.5s ease-in-out;
}

.message-pane .text {
  pointer-events: none;
}

.message-pane i.close {
  position: absolute;
  top: -0.625rem;
  right: -0.375rem;
  height: 1em;
  width: 1em;
  font-size: 2.5rem;
}

.message-pane i.close:hover {
  color: red;
}

.show-message .message-pane {
  z-index: 101;
  opacity: 1;
}

#root .show-selection .inactive {
  opacity: 0;
}

#root .actions i.icon {
  font-size: 1.5rem;
  cursor: pointer;
  width: auto;
}

#root .actions i.icon .label {
  display: inline-block;
  font-size: 0.667em;
  margin-left: 0.25em;
}

#root .horizontal > figure.segment.active figcaption {
  opacity: 0;
}

figure.segment img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

#column-images figure.segment img,
#user-images figure.segment img {
  cursor: pointer;
}

#user-images figure .status {
  position: absolute;
  font-size: 0.667rem;
  top: 1em;
  left: 1em;
  padding: 0.125em 0.25em;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 60;
  opacity: 0;
}

#user-images figure:hover .status {
  opacity: 0.5;
}

#user-images figure .status .icon {
  margin-left: 1em;
}

#user-images figure .status .trash {
  display: none;
}

.gallery .top-bar {
  position: relative;
}

.top-bar .info span,
.top-bar .info em,
#user-images figure.active .status .trash {
  display: inline-block;
}

#user-images figure.active .status .trash:hover {
  color: red;
}

#user-images figure.active .status {
  font-size: 1rem;
  opacity: 1;
  top: 0.5em;
  left: 0.5em;
}

#root .gallery .top-bar > .react-tags-wrapper {
  width: 47.25%;
  margin-left: 52.5%;
}

.top-bar .info span.small {
  font-size: 0.9em;
}

.top-bar .info span.label {
  margin-right: 0.5rem;
}

.top-bar .info span.filter {
  margin: 0 2em 0 0.25em;
}

.top-bar .info em {
  margin-right: 1em;
}

#root .gallery .top-bar .info {
  position: absolute;
  white-space: nowrap;
  font-size: 1.111111em;
  top: 0.75rem;
}

.main-images figure figcaption,
#column-images figure figcaption,
#masonry-grid figure figcaption {
  position: absolute;
  left: 2%;
  right: 2%;
  padding: 0.25em 0.5em;
  min-height: 5%;
  text-align: left;
  background: black;
  color: white;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

#masonry-grid figure figcaption {
  bottom: 1%;
}

.main-images figure figcaption {
  bottom: 5%;
}

.gallery .main-images figure figcaption,
.gallery #masonry-grid figure figcaption {
  font-size: 0.5rem;
}

.curations #masonry-grid figure figcaption {
  font-size: 0.75rem;
}

#curations-list figure > figcaption {
  padding-bottom: 2.25rem;
}

#curations-list figure .preview-image {
  position: relative;
  display: block;
}

#curations-list figure figcaption h3 {
  margin: 0.5em 0 0.25em 0;
}

#curations-list figure figcaption {
  position: relative;
  font-size: 1rem;
  margin: 0.5em 0;
}

.container .main-images figure figcaption {
  font-size: 0.75rem;
  padding: 1.25em 1.5em;
  left: 10%;
  bottom: 5%;
  right: 10%;
}

figure figcaption .caption {
  margin: 0 0 0.25em 0;
  padding: 0 0 0.25em 0;
}

figure figcaption .caption .title {
  max-width: calc(100% - 12em);
}

figure figcaption .caption .user-display-name {
  max-width: 10em;
}

figure figcaption .caption .label {
  font-size: 0.9em;
}

figure figcaption p {
  margin-block-start: 0;
  margin-block-end: 0;
}

figure figcaption p > span {
  display: inline-block;
}

figure figcaption p.description {
  margin-top: 0;
}

figure figcaption .caption .title {
  text-transform: uppercase;
  font-weight: bold;
}

figure figcaption p > span.right {
  float: right;
  margin-left: 2em;
}

figure figcaption .tags {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  margin: 0.25em 0 0.75em 0;
  align-content: flex-start;
  justify-content: flex-start;
  font-style: italic;
  margin: 0.25em 0 0 0;
  padding: 0.25em 0 0 0;
}

figure figcaption .tags li {
  margin: 0 0.5em 0 0;
  padding: 0 0.5em 0 0;
  list-style: none;
  border-right: solid 1px white;
}

figure figcaption .tags li:last-child {
  padding: 0;
  border-right: none;
}

figure.segment figcaption p {
  clear: both;
  margin: 0;
  padding: 0 0 0.125em 0;
}

#root .container.thumbnails figure.segment:hover {
  transform: scale(1.125);
  z-index: 50;
}

#user-images > figure.inactive img,
#masonry-grid figure.segment:hover img,
.container.thumbnails figure.segment:hover {
  cursor: zoom-in;
}

#user-images {
  display: block;
}

#user-images > figure {
  break-inside: avoid;
}

#root .user-images figure.segment.active,
#user-images figure.segment:hover {
  transform: scale(1);
}

#root .user-images.show-selection figure.segment.active {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
}

#root .user-images.show-selection figure.segment.active figcaption {
  display: none;
}

#root .user-images.show-selection .horizontal {
  min-height: calc(100vh - 7.5em);
  max-height: calc(100vh - 5em);
}

#root .user-images figure.segment.active .icon {
  font-size: 1rem;
  z-index: 59;
}

#root .user-images figure.segment.active .icon.close {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  padding: 0.25em 0.5em;
  height: 2em;
  width: 2em;
  border-radius: 1em;
  background-color: rgba(255, 255, 255, 0.5);
  transition: all 0.33s ease-in-out;
  cursor: pointer;
}

#root .user-images.show-selection figure.inactive {
  display: none;
}

#root .user-images figure.segment.active .icon.close:hover {
  color: red;
}

#root .user-images figure.segment.active .icon.close:before {
  position: absolute;
  top: 0;
  left: 0.375em;
  font-size: 1.5rem;
}

#root .user-images figure.segment.active .icon.close:hover {
  transform: scale(1.33);
}

#root .user-images figure figcaption {
  font-size: 0.75rem;
}

#root .textarea,
#root .react-tags-wrapper,
#root .textfield-long {
  width: 100%;
}

#root .textfield-long {
  margin: 0.5em 0;
}

#root .cloudinary-button {
  width: 100%;
  font-size: 1em;
  text-transform: uppercase;
  box-shadow: none;
  background-color: black;
  color: white;
  border-radius: 0;
  transition: background-color 0.25 ease-in-out;
}

.uploader .agree-to-terms-row {
  margin: 1em 0;
  color: green;
}

.uploader .expand-text,
.uploader #agree-to-terms {
  margin-right: 0.25em;
}

.uploader .expand-text {
  cursor: pointer;
  padding: 0.25em 0.5em;
  margin-top: 1em;
  margin-bottom: 1em;
  border: solid 1px black;
}

.uploader .text {
  margin-top: 1em;
}

#root .cloudinary-button:hover {
  background-color: #999999;
}

#root .clear {
  clear: both;
}

#root footer.footer {
  position: relative;
  width: 100%;
  clear: both;
}

#root footer.footer > section {
  min-height: 18em;
  padding: 1em 0;
}

footer.footer section.top {
  background-color: black;
}

footer.footer section.top a,
footer.footer section.top {
  color: white;
}

footer.footer section.bottom .inner h2 {
  font-size: 1em;
}

footer.footer section .inner-wrapper .inner {
  padding: 1em 5%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

#root .cloudinary-button i.icon {
  margin-left: 0.5em;
  font-size: 1.25rem;
}

#root .react-tags-wrapper .ReactTags__tagInput {
  display: inline-block;
  position: relative;
  margin: 0.5em 0;
}

#root .react-tags-wrapper .ReactTags__suggestions {
  position: absolute;
  top: 1.75em;
  left: 0;
  color: #999999;
  background: white;
  padding: 0.25em 0.25 0.375em 0.25em;
  border: solid 1px #999999;
  z-index: 20;
}

#root .gallery .react-tags-wrapper .ReactTags__suggestions li:hover {
  background-color: #fafafa;
}

#root .react-tags-wrapper .ReactTags__suggestions ul,
#root .react-tags-wrapper .ReactTags__suggestions li {
  list-style: none;
  margin: 0;
}

#root .react-tags-wrapper .ReactTags__suggestions ul {
  padding: 0;
}

#root .react-tags-wrapper .ReactTags__suggestions li {
  padding: 0.25em 0;
  min-width: 8em;
  cursor: pointer;
}

#root .actions i.save:hover,
#root .react-tags-wrapper .ReactTags__suggestions li:hover {
  color: #000099;
}

#root .react-tags-wrapper input {
  min-width: 10em;
}

#root .react-tags-wrapper .ReactTags__remove {
  font-size: 1.5rem;
  margin: 0 1em 0 0.375em;
  cursor: pointer;
  color: red;
}

#root .gallery .react-tags-wrapper .ReactTags__remove {
  font-size: 2.5rem;
  position: absolute;
  top: -0.5em;
  right: -1.75em;
  filter: grayscale(100%);
  transition: filter 0.333s ease-in-out;
}

#root .gallery .react-tags-wrapper:hover .ReactTags__remove {
  filter: grayscale(0%);
}

#column-images figure.segment figcaption {
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 1rem;
  display: flex;
  flex-flow: row wrap;
}

#column-images figure.segment figcaption p {
  margin-right: 2em;
}

#column-images figure.segment:hover figcaption,
#masonry-grid figure.segment:hover figcaption {
  opacity: 1;
}

.menu li strong,
.menu li em {
  display: inline-block;
}

.menu li em {
  margin-left: 0.5em;
}

.footer .logos {
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: 0.5em 0;
}

.footer .logos,
.footer .logos figure {
  position: relative;
}

.footer .logos figure {
  max-width: 80%;
  padding: 1em 5% 1.5em 5%;
}

.footer .logos figure img {
  position: relative;
  max-height: 4em;
  max-width: 100%;
  width: auto;
  height: auto;
  margin: 0 auto;
}

.footer .logos figure img.somerset-house {
  transform: scale(1.5);
}

.footer .top .inner {
  height: 10rem;
}

.footer h2 {
  text-align: center;
  text-transform: uppercase;
  margin: 0 auto;
}

.footer .top .inner h2.side-title {
  position: absolute;
  top: 0;
  left: 2%;
  transform: rotate(270deg) translate(-10rem, 0.5rem);
  transform-origin: 0 0;
  width: 10rem;
  overflow: hidden;
}

.footer .logos figure figcaption {
  position: absolute;
  left: -999em;
  top: 0;
}

.container .swipeable {
  display: none;
}

.main-images-container,
.container .main-images,
.container .swipeable,
.container .backdrop {
  position: fixed;
}

.show-main .main-images-container.active {
  z-index: 14;
  opacity: 1;
  pointer-events: all;
  transition: opacity 0.5s ease-in-out;
}

.main-images-container,
.container .backdrop,
.container .swipeable {
  left: 0;
  right: 0;
  bottom: 0;
}

.main-images-container,
.container .backdrop {
  top: 0;
  z-index: -1;
  opacity: 0;
  background-color: black;
  color: white;
  z-index: -1;
  pointer-events: none;
}

.container .backdrop .back {
  background-position: center center;
  background-size: 70%;
}

.show-main .backdrop {
  opacity: 1;
  z-index: 15;
  pointer-events: all;
}

.backdrop .back {
  position: absolute;
  bottom: 0;
  left: 0;
  cursor: pointer;
  width: 5vw;
  height: 5vw;
  transition: transform 0.5s ease-in-out;
  transform: rotate(135deg);
}

.backdrop .back:hover {
  transform: rotate(-30deg);
}

.show-main .main-images {
  top: 2vw;
  left: 2vw;
  bottom: 2vw;
  right: 2vw;
  z-index: 52;
}

.s how-main .main-images {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-flow: row nowrap;
}

#root .main-images figure {
  object-fit: contain;
  object-position: center;
  position: absolute;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scale(1, 1);
  transition: opacity 0.9s ease-in-out;
}

.show-main .main-images figure.active {
  opacity: 1;
  transition: opacity 0.9s ease-in-out, transform 0.9s ease-in-out;
}

.show-main .main-images figure.inactive {
  opacity: 0;
  pointer-events: none;
}

.show-main .main-images.stable figure.inactive {
  transform: scale(-1, 1);
}

.show-main .main-images figure img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  margin: 0 auto;
}

.show-main .main-images figure picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.main-images .icon {
  position: absolute;
  transition: all 0.333s ease-in-out;
  opacity: 0.5;
  font-size: 8vw;
  font-style: normal;
}

.show-main .main-images figure figcaption:hover,
.show-main .main-images figure.over figcaption {
  opacity: 1;
}

#root .main-images figure > i.heart {
  position: absolute;
  top: -0.375em;
  left: -0.375em;
  height: 1.5em;
  width: 1.5em;
  transform: scale(0.6667);
  color: red;
  opacity: 0.75;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

#root .main-images figure.active:hover > i.heart {
  opacity: 0.6667;
  pointer-events: all;
  z-index: 123;
  user-select: none;
}

#root .main-images figure i.heart > span.num {
  color: white;
  font-size: 0.33em;
  position: absolute;
  bottom: -0.5em;
  display: inline-block;
  width: 4.5em;
  left: 0;
  text-align: center;
  font-weight: bold;
  transition: unset;
}

.main-images figure .social-share-buttons {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  justify-content: center;
}

.main-images figure .social-share-buttons .SocialMediaShareButton {
  height: 1.25rem;
  width: 1.25rem;
  position: relative;
  margin: 0 0.5rem;
  outline: none;
}

.main-images figure .social-share-buttons .SocialMediaShareButton i {
  color: white;
}

.main-images figure .social-share-buttons .SocialMediaShareButton:hover i {
  color: white;
  opacity: 1;
}

.main-images figure .social-share-buttons i.icon {
  font-size: 1.25rem;
  position: relative;
}

#root .main-images figure.active:hover > i.heart:hover {
  transform: scale(0.9);
}

.main-images .icon:hover,
.main-images .icon:hover {
  opacity: 1;
  cursor: pointer;
}

.main-images .close {
  top: -0.625em;
  right: -0.375em;
  z-index: 56;
  font-size: 10vw;
}

.main-images .arrow {
  top: 7.5vh;
  bottom: 7.5vh;
  height: 85%;
  width: 10%;
  z-index: 131;
  outline: none;
  user-select: none;
  cursor: pointer;
}

.main-images .arrow:before {
  position: absolute;
  top: 47.5%;
}

.main-images .arrow.left:before {
  left: 0.125em;
}

.main-images .arrow.right:before {
  right: 0.125em;
}

.main-images .arrow.left {
  left: -2vw;
}

.main-images .arrow.right {
  right: -2vw;
}

.container #user-images figure figcaption {
  position: relative;
  color: black;
  left: 0;
  top: 0;
  background: none;
  opacity: 1;
  padding: 0.5em 0 1em 0;
}

.container #user-images figure figcaption > i.edit {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 1rem;
}

.container #user-images figure:hover figcaption > i.edit {
  color: green;
}

.container #user-images figure figcaption p {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-top: 0;
  text-align: left;
}

.container #user-images figure figcaption p.needs-caption {
  opacity: 0.5;
}

.container #user-images figure .status .icon {
  cursor: pointer;
}

#root .container #user-images figure .icon.warning.standalone {
  position: absolute;
  top: 0.625em;
  right: 0.75em;
  transform: scale(2);
  font-size: 1rem;
  opacity: 0.375;
  transition: opacity 0.333s ease-in-out;
  pointer-events: none;
}

#root .container #user-images figure:hover .icon.warning.standalone {
  opacity: 0.625;
}

#root .container #user-images figure.active .icon.warning.standalone {
  display: none;
}

.container #user-images figure .icon.warning {
  color: red;
}

.container #user-images figure.large-placeholder figcaption p {
  text-align: center;
}

.container #user-images figure figcaption p.caption {
  margin-right: 1.5em;
}

figure figcaption p span,
figure figcaption p em {
  display: inline-block;
}

figure figcaption p .label {
  margin-right: 0.5em;
}

figure figcaption p em.label:after {
  content: ':';
}

.large-placeholder > i.icon {
  position: relative;
  font-size: 10rem;
  height: 1em;
  margin: 0;
  width: 100%;
  opacity: 0.5;
}

.container #user-images .large-placeholder {
  min-width: 10rem;
}

.container #user-images .large-placeholder > figcaption {
  margin: 2em 0 0 0;
  text-align: center;
}

#user-images figure figcaption ul.tags li {
  border-right: solid 1px black;
  line-height: 1em;
}
.main-content > .segments {
  clear: both;
}
#user-images figure figcaption ul.tags {
  margin: 0.5em 0;
}

#user-images figure figcaption ul.tags li:last-child {
  border-right: none;
}

#masonry-grid.cols-1 > .column {
  width: 100%;
}

#masonry-grid.cols-2 > .column {
  width: 50%;
}

#masonry-grid.cols-3 > .column {
  width: 33.33%;
}

#masonry-grid.cols-4 > .column {
  width: 25%;
}

#masonry-grid.cols-5 > .column {
  width: 20%;
}

#masonry-grid.cols-6 > .column {
  width: 16.6666%;
}

.container .inner-content {
  position: relative;
}

.text-content table td,
.text-content table th {
  vertical-align: top;
  border-spacing: 0.25em;
  padding: 0.25em;
}

.text-content table tbody td {
  font-size: 0.9em;
}

.container .inner-content .left {
  float: left;
  margin-right: 2%;
}

.container .inner-content .left,
.container .inner-content .text-content {
  margin-left: 0.625rem;
}

#curations-list {
  flex-flow: column wrap;
}

#curations-list > figure {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
}

#curations-list > figure img {
  min-width: 100%;
}

.main-area .wp-block-gallery li {
  list-style-type: none;
  margin-bottom: 1.5em;
}

.main-area .wp-block-gallery li figure img {
  width: 100%;
  height: auto;
}

.news-items .wp-block-gallery li {
  display: none;
}

.news-items .wp-block-gallery li:first-child {
  display: block;
}

@media screen and (min-width: 440px) {
  #root .container #masonry-grid {
    display: flex;
    flex-flow: row nowrap;
  }

  #masonry-grid > .column {
    width: 50%;
    min-width: 49%;
  }
  header .logo {
    height: 10em;
  }
}

@media screen and (max-width: 659px) {
  .news .main-content > h1,
  .right-col-offset .main-content > h1,
  .right-col-offset .main-content > aside > h1 {
    left: -1rem;
  }
  ,
  .right-col-offset .main-content > aside > h1 {
  }
  .main-content > .text-content,
  .right-col-offset .main-content > time,
  #root .right-col-offset .main-content > aside > .login-form,
  .right-col-offset .main-content > aside > article,
  .right-col-offset .main-content > aside > time {
    margin-left: 2rem;
  }
  .right-col-offset .main-content > article.text-content {
    margin-left: 3rem;
  }
}

@media screen and (min-width: 660px) {
  #masonry-grid > .column {
    width: 33.33%;
    min-width: 32.83%;
  }
  header .logo {
    height: 12em;
  }

  .container .main-images figure figcaption {
    left: 17.5%;
    right: 17.5%;
  }

  .main-images .icon {
    font-size: 6vw;
  }

  .show-main .main-images {
    top: 4vw;
    left: 4vw;
    bottom: 4vw;
    right: 4vw;
  }
  .main-images .arrow.left {
    left: -4vw;
  }

  .main-images .arrow.right {
    right: -4vw;
  }

  #root .main-images figure > i.heart {
    top: -0.5em;
    left: -0.5em;
  }
  #root .main-images i.close {
    top: -0.875em;
    right: -0.875em;
  }

  #curations-list figure > figcaption,
  #curations-list figure > a {
    padding-right: 1.5rem;
    width: calc(100% - 1.5rem);
  }

  #curations-list {
    display: flex;
    flex-flow: row wrap;
    margin-left: 3rem;
    margin-right: -1.5em;
  }

  #curations-list > figure {
    width: 50%;
    max-width: 50%;
    margin: 0;
  }

  .hall-of-fame .container > h1 {
    left: 0.125em;
    font-size: 1.5em;
  }
  #user-images {
    column-count: 2;
  }

  .inner-wrapper .message-pane {
    left: 5%;
    right: 5%;
    font-size: 1.1667em;
  }
}

@media screen and (max-width: 879px) {
  #root .contribute .main-content aside.side-panel > h1 {
    font-size: 1.25rem;
  }
  #root header.show-menu nav.main-nav ul.menu {
    max-height: none;
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.9);
    pointer-events: all;
    height: 100vh;
    z-index: 22;
  }
  #root .black-bg header.show-menu nav.main-nav ul.menu {
    background-color: rgba(0, 0, 0, 0.9);
  }
  #root .react-tags-wrapper {
    visibility: hidden;
  }
  .text-contracted .uploader .text {
    max-height: 200em;
    transition: max-height 1s ease-in-out;
  }

  #root .text-contracted .uploader .text {
    max-height: 0;
    overflow: hidden;
  }

  .container .main-images-container.active .swipeable {
    display: block;
    user-select: none;
    z-index: 200;
    top: 60%;
  }

  .main-images .close {
    height: 3em;
    width: 3em;
  }
  .main-images .close:before {
    position: absolute;
    top: 0;
    right: 0.125em;
  }
}

@media screen and (min-width: 880px) {
  header .logo {
    height: 15em;
    max-width: 50%;
  }

  #root .mobile-only {
    display: none;
  }

  #root .main-menu li a {
    line-height: 1.25em;
  }

  #root header .main-nav i.bars {
    display: none;
  }

  .container .inner-content,
  #masonry-grid {
    padding-left: 3rem;
  }

  .inner-wrapper > .container > .inner-content {
    padding: 1.5rem 0 2rem 3rem;
  }

  #masonry-grid > .column {
    width: 25%;
    min-width: 24.5%;
  }

  #root .container .user-images {
    top: -1rem;
    min-height: 80vh;
  }

  #root .container .user-images.show-selection figure > div.preview {
    position: relative;
    height: 100%;
    width: 100%;
    text-align: center;
    max-height: 100%;
    margin: 0 auto;
  }

  #root header .main-nav .user-widget {
    padding: 0 0 0 3rem;
    position: relative;
    top: 0;
    right: auto;
  }

  .footer .top .inner h2.side-title {
    left: 5%;
  }

  footer.footer .footer-menu {
    margin-top: 0;
  }

  footer.footer .credit-links {
    margin-bottom: 0;
  }

  #root .user-details {
    margin: 0.5em 3em 0 3rem;
  }

  header nav.main-nav a {
    left: -0.25em;
  }

  header nav.main-nav a.contribute {
    left: -0.375em;
  }

  header nav.main-nav li:hover a.contribute,
  header nav.main-nav li.active a.contribute {
    border-color: white;
  }

  #root .black-bg header nav.main-nav li:hover a.contribute,
  #root .black-bg header nav.main-nav li.active a.contribute {
    border-color: black;
  }

  .right-col-offset .main-content > aside > h1 {
    left: -0.375em;
  }

  .right-col-offset .main-content .two-thirds {
    width: 50%;
    float: left;
    margin-left: 0;
  }

  .news .right-col-offset .main-content .two-thirds {
    min-height: 80rem;
  }

  #curations-list,
  .ui.segments.news-items {
    display: flex;
    flex-flow: row wrap;
    margin-right: -1.5em;
  }

  .news-items article.post {
    width: 50%;
    max-width: 50%;
  }

  .news-items article.post .media,
  .news-items article.post .text-content {
    padding-right: 1.5em;
  }

  .news-items article.post .media figcaption {
    display: none;
  }

  #root .segments.news-items article {
    clear: none;
  }

  .right-col-offset .two-thirds .wp-block-embed__wrapper iframe {
    height: 28.125vw;
  }

  .right-col-offset .main-content > aside {
    width: 45%;
    float: right;
    margin-left: -2.5%;
    margin-top: -0.125rem;
  }

  .news-items article.post.odd > .text-content {
  }

  header nav.main-nav {
    position: relative;
    float: right;
    min-width: 45%;
    margin-left: 2%;
    max-width: 46%;
    overflow: hidden;
  }

  header nav.main-nav ul.menu {
    position: relative;
    top: 0;
    max-height: none;
    opacity: 1;
    pointer-events: all;
    text-align: left;
    overflow: visible;
    margin-left: 3rem;
    padding: 0;
  }

  #root footer.footer > section {
    min-height: 8em;
  }

  .footer .logos {
    max-height: 6em;
    flex-flow: row nowrap;
  }

  .footer .logos figure {
    max-width: 30%;
    padding: 1em 5%;
  }

  #root header .user-details {
    margin: 1.5em 0;
  }
  #root footer.footer .menu {
    display: flex;
    flex-flow: row nowrap;
  }
  #root footer.footer .footer-menu li {
    margin: 0.5em 1em;
    white-space: nowrap;
  }

  #root footer.footer .credit-links {
    margin-top: 3rem;
    margin-bottom: 2rem;
    font-size: 0.8em;
  }

  #root footer.footer .credit-links li {
    border-right: solid 1px white;
    padding: 0 1rem 0 0;
    margin: 1em 0.5rem;
  }

  #root footer.footer .credit-links li:last-child {
    border-right: none;
  }

  .gallery .top-bar {
    display: flex;
    flex-flow: row nowrap;
    max-height: 2em;
    width: 100%;
  }

  #root .gallery .top-bar .info {
    left: 3rem;
  }

  .gallery .container > h1.main {
    position: absolute;
    top: 43rem;
    left: 0.125em;
    font-size: 1.5em;
    white-space: nowrap;
    margin-right: 1em;
    width: 40rem;
    transform: rotate(-90deg);
    transform-origin: 0 0;
    z-index: 14;
    text-align: right;
    margin-top: 0;
    padding-top: 0;
  }

  .gallery .container > h1.main {
    top: 44rem;
  }

  .gallery .container > h1.main .title {
    font-size: 1.3333em;
  }

  .gallery .container > h1.main span.filter,
  .gallery .container > h1.main .num-images {
    position: absolute;
    top: 0;
  }

  .gallery .container > h1.main span.filter {
    right: 22rem;
  }
  .gallery .container > h1.main .num-images {
    transform: rotate(90deg);
    right: 18rem;
    font-size: 0.6667em;
  }

  .container .main-images figure figcaption {
    left: 25%;
    right: 25%;
  }

  #root .gallery .top-bar > .react-tags-wrapper {
    width: 46%;
    margin-left: 53.5%;
    min-height: 1.5rem;
  }

  .main-images i.close,
  .main-images .icon {
    font-size: 4vw;
  }
  .main-images .close {
    font-size: 5vw;
  }

  #root .main-images figure > i.heart {
    top: -0.875em;
    left: -0.875em;
  }

  #curations-list > figure {
    width: 33.333%;
    max-width: 33.333%;
  }
  #root .main-images figure > i.heart {
    top: -1.25em;
    left: -1.25em;
  }
  #root .main-images i.close {
    top: -1.125em;
    right: -1.125em;
  }
  #column-images > figure {
    max-width: 90%;
  }
  .inner-wrapper .message-pane {
    font-size: 1.0625em;
  }
}

@media screen and (min-width: 1024px) {
  header nav.main-nav {
    max-width: 25%;
  }
  header ul.main-menu {
    margin-left: 3em;
  }

  #column-images > figure {
    max-width: 80%;
  }
}

@media screen and (min-width: 1100px) {
  header .logo {
    height: 16em;
  }

  #masonry-grid > .column {
    width: 20%;
    min-width: 19.5%;
  }
  ul.main-menu li {
    font-size: 1.667em;
  }

  .right-col-offset .main-content .two-thirds {
    width: 66.6667%;
    margin-top: 1.5rem;
  }

  .right-col-offset .main-content > aside {
    width: 30%;
    margin-top: 1.375em;
  }

  #curations-list > figure,
  .news-items article.post {
    width: 33.333%;
    max-width: 33.333%;
  }

  .right-col-offset .two-thirds .wp-block-embed__wrapper iframe {
    height: 33.75vw;
  }

  #root header nav.main-nav {
    min-width: 30%;
  }
  .gallery .top-bar h1 {
    width: 66.667%;
  }

  .container .main-images figure figcaption {
    left: 30%;
    right: 30%;
  }

  #root .gallery .top-bar > .react-tags-wrapper {
    width: 56.25%;
    margin-left: 43.5%;
  }

  #curations-list > figure {
    width: 25%;
    max-width: 25%;
  }

  .container > .text-content,
  .container .inner-content .text-content {
    max-width: 66.6667%;
  }
  #user-images {
    column-count: 3;
  }
  .inner-wrapper .message-pane {
    font-size: 1.125em;
  }
}

@media screen and (min-width: 1320px) {
  #masonry-grid > .column {
    width: 16.666%;
    min-width: 16.166%;
  }
  ul.main-menu li {
    font-size: 1.8333em;
  }

  .container .main-images figure figcaption {
    left: 35%;
    right: 35%;
  }

  #root .gallery .top-bar > .react-tags-wrapper {
    width: 48%;
    margin-left: 52.5%;
  }
  #column-images > figure {
    max-width: 75%;
  }

  .inner-wrapper .message-pane {
    font-size: 1.375em;
  }
}

@media screen and (min-width: 1600px) {
  .right-col-offset .two-thirds .wp-block-embed__wrapper iframe {
    height: 540px;
  }
  .main-images i.close,
  .main-images .icon {
    font-size: 64px;
  }
  .main-images .close {
    font-size: 80px;
  }

  .wrapper > header {
    padding: 1.5em 80px 1em 80px;
  }
  #column-images > figure {
    margin-bottom: 48px;
  }
}
